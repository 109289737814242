import { A, Button, CheckButton } from "@/components/input";
import ScrollOnUpdate from "@/components/scrollonupdate";
import { getIdsInView } from "@/lib/isvisible";
import { persistentSignal } from "@/lib/persistentsignal";
import {
  ChangeDescriptionType,
  ChatAssistantType,
  ChatItemType,
  ChatQuestionType,
  ChatToolType,
  ChatUserType,
  isChatAssistantType,
  isChatQuestionType,
  isChatToolType,
  isChatUserType,
  ItemType,
  QuestionType,
} from "@/lib/types";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/16/solid";
import { signal, useSignal } from "@preact/signals-react";
import React, { Fragment } from "react";
import { KeyboardEvent, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
// import Markdown from "react-markdown";
import { Markdown } from "@/components/markdown";
import { IndexedSignal } from "@/lib/signalutil";
import { trackSettled } from "@/lib/tracksettled";
import { highlightElementsWithOverlay } from "@/lib/highlightelement";
import { UpdateItemQuestionType } from "@/lib/chatllm";

export const myUidSignal = persistentSignal<string | null>("myUid", null);
const myProjectIdSignal = persistentSignal<number | null>("myProjectId", null);
const randomCode = signal(0);

const sessionIdSignal = persistentSignal<number | null>("chatSessionId", null);
const chatSignal = signal<ChatItemType[] | null>(null);
const databaseViewItems = signal<ItemType[]>([]);

if (typeof window !== "undefined") {
  (window as any).chatSignal = chatSignal;
}

export default function Home() {
  if (!myUidSignal.value) {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Login />
      </div>
    );
  }
  return (
    <div className="h-screen flex flex-col">
      <div className="p-2 fixed w-full top-0 flex justify-between border-b">
        <span></span>
        {myUidSignal.value ? (
          <span className="font-mono text-xs">User: {myUidSignal.value}</span>
        ) : (
          <span className="font-mono text-xs">User: Not logged in</span>
        )}
      </div>

      <div className="flex flex-1 pt-8 md:overflow-hidden flex-col md:flex-row">
        <div className="w-full md:w-1/3 mr-2 flex flex-col p-4">
          <ScrollOnUpdate
            className="flex-1 overflow-y-auto p-2"
            watch={sessionIdSignal.value}
            watch2={chatSignal.value}
            watch3={null}
          >
            <ChatLog />
          </ScrollOnUpdate>
          <Input />
        </div>
        <div className="w-full md:w-2/3 flex flex-col h-full overflow-y-scroll">
          <DatabaseView />
        </div>
      </div>
    </div>
  );
}

function Login() {
  useEffect(() => {
    if (!randomCode.value) {
      randomCode.value = Math.floor(Math.random() * 9000) + 1000;
    }
  });
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!randomCode.value) {
        return;
      }
      const resp = await fetch(
        `/api/omi/user/check?code=${encodeURIComponent(randomCode.value)}`
      );
      const data = await resp.json();
      console.log("Got check", data, randomCode.value);
      if (data.uid) {
        myUidSignal.value = data.uid;
      }
      if (data.projectId) {
        myProjectIdSignal.value = data.projectId;
      }
    }, 500);
    return () => clearInterval(interval);
  });
  useEffect(() => {});
  return (
    <div>
      <p className="text-lg mb-4">
        You're accessing the <A href="/">Memory Atlas</A> application. To
        authenticate use your{" "}
        <A href="https://www.omi.me/" blank>
          Omi device
        </A>{" "}
        to pair it with this application:
      </p>
      <p className="text-lg mb-4">
        Say &quot;hello&quot; and speek the following digits into the mic:
      </p>
      <p className="text-4xl font-bold font-mono">hello {randomCode.value}</p>
    </div>
  );
}

function DatabaseView() {
  useEffect(() => {
    if (databaseViewItems.value.length === 0) {
      refreshDatabase();
    }
  }, [databaseViewItems]);
  return <DatabaseViewItems items={databaseViewItems.value} />;
}

function DatabaseViewItems({ items }: { items: ItemType[] }) {
  const detailing = useSignal<number>(-1);
  return (
    <div>
      {items.length === 0 ? (
        <div>Loading...</div>
      ) : (
        <table className="normal-table w-full">
          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Description</th>
              <th colSpan={2}>Location</th>
            </tr>
          </thead>
          <tbody>
            {items.map((log, i) => {
              const prev = i > 0 ? items[i - 1] : null;
              const repeatedLocation =
                prev &&
                prev.location === log.location &&
                detailing.value !== log.id;
              function infoClick(event: React.MouseEvent<any>) {
                event.stopPropagation();
                if (detailing.value === log.id) {
                  detailing.value = -1;
                } else {
                  detailing.value = log.id!;
                }
              }
              return (
                <Fragment key={i}>
                  <tr key={i}>
                    <td className="text-xs" data-id={log.id}>
                      #{log.id}
                    </td>
                    <td>{log.name}</td>
                    <td>{log.description}</td>
                    <td
                      className={twMerge(
                        "text-xs",
                        repeatedLocation ? "opacity-50" : ""
                      )}
                    >
                      {log.location}
                    </td>
                    <td
                      rowSpan={detailing.value === log.id ? 2 : 1}
                      className="hover:bg-blue-100"
                      onClick={infoClick}
                    >
                      <button
                        className={twMerge(
                          "px-2 text-gray-400 hover:text-blue-700 hover:bg-blue-100",
                          detailing.value === log.id ? "text-blue-500" : ""
                        )}
                        onClick={infoClick}
                      >
                        <InformationCircleIcon className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                  {detailing.value === log.id && (
                    <tr>
                      <td colSpan={4}>
                        <div className="flex">
                          <div className="flex-grow text-xs">
                            {log.fromTranscriptText}
                          </div>
                          <div className="">
                            <Button
                              onClick={async () => {
                                trackSettled.run(async () => {
                                  await fetch(
                                    `/api/llm/explode?transcript_id=${encodeURIComponent(log.fromTranscriptId!)}`
                                  );
                                  await refreshDatabase();
                                });
                              }}
                            >
                              <ArrowPathIcon className="w-3 h-3" />
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

function Input() {
  async function onSubmitMessage(input: string) {
    trackSettled.run(async () => {
      if (!myUidSignal.value) {
        return;
      }
      const visibleIds = getIdsInView();
      const messages: ChatItemType[] = [
        {
          role: "user",
          text: input,
        } as ChatUserType,
      ];
      chatSignal.value = [...(chatSignal.value || []), ...messages];
      if (!myProjectIdSignal.value) {
        const projResp = await fetch(
          `/api/omi/project?uid=${encodeURIComponent(myUidSignal.value)}&name=Inventory`
        );
        const projData = await projResp.json();
        myProjectIdSignal.value = projData.projectId;
      }
      const resp = await fetch("/api/llm/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: myUidSignal.value,
          // FIXME: this will break badly with multiple users!
          projectId: myProjectIdSignal.value,
          sessionId: sessionIdSignal.value,
          messages,
          visibleIds,
        }),
      });
      const data = await resp.json();
      chatSignal.value = [...(chatSignal.value || []), ...data.messages];
      sessionIdSignal.value = data.sessionId;
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    });
  }
  // FIX for a lack of using a signal for model.lastSuggestions
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  async function onKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  }
  async function onSubmit() {
    if (!textareaRef.current) {
      return;
    }
    const text = textareaRef.current.value;
    if (!text) {
      return;
    }
    const newText = "";
    textareaRef.current.value = newText;
    await trackSettled.run(onSubmitMessage(text));
    setTimeout(() => {
      textareaRef.current!.focus();
    }, 0);
  }
  async function onNewSession(event: React.MouseEvent<HTMLButtonElement>) {
    sessionIdSignal.value = null;
    chatSignal.value = [];
    textareaRef.current!.focus();
  }
  async function onUndo(event: React.MouseEvent<HTMLButtonElement>) {
    return trackSettled.run(async () => {
      const resp = await fetch("/api/llm/chat/undo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: sessionIdSignal.value,
        }),
      });
      const data = await resp.json();
      if (textareaRef.current && typeof data.lastText === "string") {
        textareaRef.current.value = data.lastText;
        textareaRef.current.focus();
      }
      chatSignal.value = data.messages;
    });
  }
  const placeholder = trackSettled.runningSignal.value
    ? "Communicating with assistant..."
    : "Enter a question, search, or command";
  return (
    <div className="flex mt-4">
      <textarea
        ref={textareaRef}
        rows={2}
        className={twMerge(
          "flex-1 resize-none bg-white border-none p-2",
          trackSettled.runningSignal.value ? "opacity-50" : ""
        )}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        disabled={trackSettled.runningSignal.value}
      />
      <div className="flex flex-col ml-2">
        <Button
          className="bg-green-600 text-green-100"
          onClick={onSubmit}
          disabled={trackSettled.runningSignal.value}
        >
          Send
        </Button>
        <Button
          className="bg-yellow-500 text-yellow-900"
          onClick={onUndo}
          disabled={trackSettled.runningSignal.value}
        >
          Undo
        </Button>
        <Button
          className="bg-red-500 text-red-900"
          onClick={onNewSession}
          disabled={trackSettled.runningSignal.value}
        >
          New
        </Button>
      </div>
    </div>
  );
}

function ChatLog() {
  useEffect(() => {
    console.log(
      "Loading chatlot",
      chatSignal.value,
      myUidSignal.value,
      sessionIdSignal.value
    );
    if (chatSignal.value === null && myUidSignal.value) {
      if (!sessionIdSignal.value) {
        chatSignal.value = [];
      } else {
        fetch(
          `/api/llm/chat/fetch?uid=${encodeURIComponent(myUidSignal.value)}&sessionId=${encodeURIComponent(sessionIdSignal.value)}`
        )
          .then((resp) => resp.json())
          .then((data) => {
            console.log("messages:", data.messages);
            chatSignal.value = data.messages;
          })
          .catch((err) => {
            console.error("Failed to fetch chat", err);
          });
      }
    }
  }, [chatSignal, myUidSignal, sessionIdSignal]);
  if (!chatSignal.value) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {chatSignal.value.map((item, i) => {
        if (isChatAssistantType(item)) {
          return <AssistantMessage key={i} item={item} />;
        } else if (isChatUserType(item)) {
          return <UserMessage key={i} item={item} />;
        } else if (isChatToolType(item)) {
          return <ToolMessage key={i} item={item} />;
        } else if (isChatQuestionType(item)) {
          return <QuestionMessage key={i} item={item} />;
        } else {
          return <div key={i}>Unknown message type</div>;
        }
      })}
    </div>
  );
}

function Message({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={twMerge("rounded-md mb-2 p-2", className)}>{children}</div>
  );
}

function AssistantMessage({ item }: { item: ChatAssistantType }) {
  if (!item.text) {
    return null;
  }
  return (
    <Message className="bg-blue-300 ml-4">
      {/* {React.createElement(Markdown, null, item.text)} */}
      <Markdown>{item.text}</Markdown>
      {/* <Markdown>{"hello"}</Markdown> */}
      {/* <pre className="font-sans whitespace-pre-wrap">{item.text}</pre> */}
    </Message>
  );
}

function UserMessage({ item }: { item: ChatUserType }) {
  return (
    <Message className="bg-gray-300 mr-4">
      <pre className="font-sans whitespace-pre-wrap">{item.text}</pre>
    </Message>
  );
}

function ToolMessage({ item }: { item: ChatToolType }) {
  if (item.structured && item.structured.searchResults) {
    return (
      <ToolMessageSearchResults
        item={item}
        query={item.structured.query}
        results={item.structured.searchResults}
      />
    );
  }
  return (
    <Message className="bg-blue-800 text-white border-gray-100 border-2 text-xs ml-4 rounded-lg px-2">
      <div>{item.content}</div>
    </Message>
  );
}

function ToolMessageSearchResults({
  item,
  query,
  results,
}: {
  item: ChatToolType;
  query: { location: string; text: string };
  results: ItemType[];
}) {
  const desc: string[] = [];
  if (query?.text) {
    desc.push(`Search for ${JSON.stringify(query.text)}`);
    if (query?.location) {
      desc.push(` AND location ${JSON.stringify(query.location)}`);
    }
  } else if (query?.location) {
    desc.push(`Search for location ${JSON.stringify(query.location)}`);
  } else {
    desc.push("Search results");
  }

  return (
    <Message className="bg-blue-800 text-white border-gray-100 border-2 text-xs ml-4 rounded-lg px-2">
      <div className="text-xs font-bold">{desc.join(" ")}:</div>
      <DatabaseViewItems items={results} />
    </Message>
  );
}

function QuestionMessage({ item }: { item: ChatQuestionType }) {
  const questionAnswers = useSignal<(boolean | null)[]>(
    item.questions.map(() => null)
  );
  const responseMessages = item.questions.map((q) => {
    const tool_call_id = q.tool_call_id;
    const responseMessage = chatSignal.value?.find(
      (m) => isChatToolType(m) && m.tool_call_id === tool_call_id
    ) as ChatToolType | undefined;
    return responseMessage;
  });
  if (responseMessages.every((m) => m)) {
    // Everything has been responded to...
    return null;
    // return (
    //   <Message className="bg-blue-200 border-blue-100 border-2">
    //     All questions responded to
    //   </Message>
    // );
  }
  const readyToConfirm = !questionAnswers.value.some((a) => a === null);
  return (
    <Message className="bg-blue-200 border-blue-100 border-2">
      <div className="font-bold text-xs">Changes to confirm or cancel:</div>
      <table className="w-full">
        <tbody>
          {item.questions.map((question, i) => {
            const subsignal = new IndexedSignal(questionAnswers, i);
            const responseMessage = responseMessages[i];
            return (
              <tr key={i}>
                <td className="align-top border-b-2 border-black">
                  <Question question={question} signal={subsignal} />
                </td>
                <td className="align-top border-b-2 border-black">
                  {responseMessage ? (
                    responseMessage.userConfirmation ? (
                      "Applied"
                    ) : (
                      "Rejected"
                    )
                  ) : (
                    <AcceptControls signal={subsignal} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        {readyToConfirm && (
          <Button
            onClick={async () => {
              trackSettled.run(async () => {
                const resp = await fetch("/api/llm/chat/confirm", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    sessionId: sessionIdSignal.value,
                    question: item,
                    answers: questionAnswers.value,
                  }),
                });
                const data = await resp.json();
                chatSignal.value = [
                  ...(chatSignal.value || []),
                  ...data.messages,
                ];
                if (data.updateTables) {
                  await refreshDatabase();
                }
              });
            }}
            disabled={!readyToConfirm}
            disabledReason="Please answer all questions"
          >
            Apply {questionAnswers.value.filter((a) => a === true).length}{" "}
            Changes
          </Button>
        )}
        {!readyToConfirm && (
          <Button
            onClick={() => {
              questionAnswers.value = questionAnswers.value.map((x) =>
                x === null ? true : x
              );
            }}
          >
            Confirm remaining{" "}
            {questionAnswers.value.filter((a) => a === null).length}
          </Button>
        )}
        <Button
          onClick={async () => {
            if (!window.confirm("Are you sure to reject all changes?")) {
              return;
            }
            trackSettled.run(async () => {
              const resp = await fetch("/api/llm/chat/confirm", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  sessionId: sessionIdSignal.value,
                  question: item,
                  cancelAll: true,
                }),
              });
              const data = await resp.json();
              chatSignal.value = [
                ...(chatSignal.value || []),
                ...data.messages,
              ];
              if (data.updateTables) {
                await refreshDatabase();
              }
            });
          }}
          secondary
        >
          Reject all
        </Button>
      </div>
    </Message>
  );
}

function AcceptControls({ signal }: { signal: IndexedSignal<boolean | null> }) {
  function triclass(on: string, off: string, unset: string) {
    if (signal.value === null) {
      return unset;
    }
    return signal.value ? on : off;
  }
  return (
    <div className="flex flex-col sticky top-0">
      <Button
        onClick={() => {
          if (signal.value === true) {
            signal.value = null;
          } else {
            signal.value = true;
          }
        }}
        className={twMerge(
          "p-2 hover:bg-green-500 mb-1 text-green-800 hover:text-black",
          triclass(
            "bg-green-500 text-black",
            "opacity-50 bg-inherit",
            "bg-inherit"
          )
        )}
        title="Accept the change"
      >
        <CheckCircleIcon className="h-4 w-4 inline" />
      </Button>
      <Button
        onClick={() => {
          if (signal.value === false) {
            signal.value = null;
          } else {
            signal.value = false;
          }
        }}
        className={twMerge(
          "p-2 hover:bg-red-500 text-red-800 hover:text-black",
          triclass(
            "opacity-50 bg-inherit",
            "bg-red-500 text-black",
            "bg-inherit"
          )
        )}
        title="Reject the change"
      >
        <XCircleIcon className="h-4 w-4 inline" />
      </Button>
    </div>
  );
}

function Question({
  question,
  signal,
}: {
  question: QuestionType;
  signal: IndexedSignal<boolean | null>;
}) {
  if (question.type === "updateMany") {
    return <UpdateManyQuestion question={question} signal={signal} />;
  } else if (question.type === "updateItem") {
    return <UpdateItemQuestion question={question} signal={signal} />;
  } else {
    return (
      <div>
        Unknown question: {question.type}{" "}
        <pre className="text-xs whitespace-pre-wrap">
          {JSON.stringify(question.question, null, 2)}
        </pre>
      </div>
    );
  }
}

function UpdateManyQuestion({
  question,
  signal,
}: {
  question: QuestionType<UpdateItemQuestionType[]>;
  signal: IndexedSignal<boolean | null>;
}) {
  return (
    <div>
      {question.explanation && (
        <div className="text-xs">{question.explanation}</div>
      )}
      {question.question.map((q: any, i: number) => {
        const changes: ChangeDescriptionType[] = [];
        const PAIRS = [
          ["name", "newName"],
          ["description", "newDescription"],
          ["location", "newLocation"],
        ];
        for (const [k, nk] of PAIRS) {
          if (q[nk]) {
            changes.push({
              key: k,
              oldValue: q[k],
              newValue: q[nk],
            });
          }
        }
        for (const [k, v] of Object.entries(q.newProperties || {})) {
          const old = q.properties[k];
          changes.push({
            key: k,
            oldValue: old,
            newValue: v,
          });
        }
        return (
          <div key={i}>
            <div className="font-bold text-xs">
              {q.name}{" "}
              <IdLink id={q.id} className="font-normal opacity-75">
                id #{q.id}
              </IdLink>
            </div>
            <div className="pl-2">
              {changes.map((c) => {
                if (!c.oldValue) {
                  return (
                    <div key={c.key} className="text-xs">
                      add <strong>{c.key}</strong>: {JSON.stringify(c.newValue)}
                    </div>
                  );
                } else if (!c.newValue) {
                  return (
                    <div key={c.key} className="text-xs">
                      remove <strong>{c.key}</strong>:{" "}
                      <s>{JSON.stringify(c.oldValue)}</s>
                    </div>
                  );
                } else {
                  return (
                    <div key={c.key} className="text-xs">
                      change <strong>{c.key}</strong>
                      {c.key}: {JSON.stringify(c.newValue)}{" "}
                      <s>{JSON.stringify(c.oldValue)}</s>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function UpdateItemQuestion({
  question,
  signal,
}: {
  question: QuestionType<UpdateItemQuestionType>;
  signal: IndexedSignal<boolean | null>;
}) {
  const id = question.question.id;
  const current = question.question.current;
  const updates = question.question.updates;
  return (
    <div>
      <div className="font-bold text-xs">
        {current.name}{" "}
        <IdLink id={id} className="font-normal opacity-75">
          id #{id}
        </IdLink>
      </div>
      <div className="pl-2">
        {Object.entries(updates).map(([k, v]) => {
          return (
            <div key={k}>
              <strong>{k}</strong>: {JSON.stringify(v)}{" "}
              <s>{JSON.stringify((current as any)[k])}</s>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function IdLink({
  className,
  id,
  children,
}: {
  className: string;
  id: number;
  children: React.ReactNode;
}) {
  function onClick(event: React.MouseEvent<any>) {
    event.preventDefault();
    event.stopPropagation();
    const elements = document.querySelectorAll(`[data-id="${id}"]`);
    if (elements.length === 0) {
      console.error("No elements with id", id);
      return;
    }
    const element = elements[0] as HTMLElement;
    // Has to be instant because of the highlight overlay
    element.scrollIntoView({ behavior: "instant", block: "center" });
    const tr = element.closest("tr");
    highlightElementsWithOverlay(tr || element);
  }
  return (
    <a
      href={`#id${id}`}
      className={twMerge("hover:underline hover:text-blue-600", className)}
      onClick={onClick}
    >
      {children || `#${id}`}
    </a>
  );
}

async function refreshDatabase() {
  const resp = await fetch(`/api/omi/fetch?uid=${myUidSignal.value}`);
  const data = await resp.json();
  databaseViewItems.value = data.rows;
}

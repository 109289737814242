import type {
  ChatCompletionCreateParamsBase,
  ChatCompletionToolMessageParam,
} from "openai/resources/chat/completions.mjs";

export type LlmLogType = {
  request: any;
  end?: number;
  response?: string;
  errorMessage?: string;
  errorBody?: any;
};

export type TranscriptSegmentType = {
  id: number;
  uid: string;
  text: string;
  speaker: string;
  speakerId: number;
  isUser: boolean;
  personId: string;
  start: number;
  end: number;
  sessionId: string;
  timestamp: number;
};

export type TranscriptTimeType = {
  text: string;
  // Timestamp in milliseconds since epoch
  start: number;
  // Length in milliseconds
  len: number;
};

export type ItemType = {
  id?: number;
  uid: string;
  projectId: number;
  fromTranscriptId?: number;
  fromTranscriptText?: string;
  name: string;
  description: string;
  location: string;
  properties: Record<string, any>;
  created?: number;
};

export type ItemWithReplacementsType = ItemType & {
  newName?: string;
  newDescription?: string;
  newLocation?: string;
  newProperties?: Record<string, any>;
};

export interface ChatPrompt
  extends Omit<
    ChatCompletionCreateParamsBase,
    | "audio"
    | "function_call"
    | "functions"
    | "modalities"
    | "store"
    | "stream"
    | "stream_options"
  > {}

export type ChatItemType =
  | ChatAssistantType
  | ChatQuestionType
  | ChatToolType
  | ChatUserType;

export type ChatBaseType = {
  id?: number;
  role: string;
  timestamp: number;
};

export type ChatAssistantType = ChatBaseType & {
  role: "assistant";
  text: string;
  tool_calls?: ChatCompletionToolMessageParam[];
};

export function isChatAssistantType(
  item: ChatItemType
): item is ChatAssistantType {
  return item.role === "assistant";
}

export type ChatToolType = ChatBaseType & {
  role: "tool";
  tool_call_id: string;
  content: string;
  structured?: any;
  userConfirmation?: boolean;
};

export function isChatToolType(item: ChatItemType): item is ChatToolType {
  return item.role === "tool";
}

export type ChatQuestionType<T = any> = ChatBaseType & {
  role: "question";
  questions: QuestionType<T>[];
};

export type QuestionType<T = any> = {
  tool_call_id: string;
  type: string;
  explanation?: string;
  question: T;
};

export function isChatQuestionType(
  item: ChatItemType
): item is ChatQuestionType {
  return item.role === "question";
}

export type ChatUserType = ChatBaseType & {
  role: "user";
  text: string;
};

export function isChatUserType(item: ChatItemType): item is ChatUserType {
  return item.role === "user";
}

export type StructuredChatCompletionToolMessageParam =
  ChatCompletionToolMessageParam & {
    structured?: any;
  };

export type ToolResultsType = {
  toolResponses?: StructuredChatCompletionToolMessageParam[];
  followupQuestions?: QuestionType[];
};

export type UserContextType = {
  uid: string;
  projectId?: number;
};

export type ChangeDescriptionType = {
  key: string;
  oldValue: any;
  newValue: any;
};

export function highlightElementsWithOverlay(
  elements: HTMLElement | HTMLElement[],
  {
    duration = 2000,
  }: {
    duration?: number;
  } = {}
) {
  if (!Array.isArray(elements)) {
    elements = [elements];
  }

  if (!elements.length) {
    console.warn("No elements to highlight");
    return;
  }

  // Initialize bounding box values
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  for (const element of elements) {
    const rect = element.getBoundingClientRect();

    // Expand the union bounding box
    minX = Math.min(minX, rect.left + window.scrollX);
    minY = Math.min(minY, rect.top + window.scrollY);
    maxX = Math.max(maxX, rect.right + window.scrollX);
    maxY = Math.max(maxY, rect.bottom + window.scrollY);
  }

  // Calculate width and height of the union bounding box
  const width = maxX - minX;
  const height = maxY - minY;

  // Create the overlay element
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = `${minY}px`;
  overlay.style.left = `${minX}px`;
  overlay.style.width = `${width}px`;
  overlay.style.height = `${height}px`;
  overlay.style.border = "2px solid red"; // Highlight border
  overlay.style.boxShadow = "0 0 10px rgba(255, 0, 0, 0.5)"; // Optional glow
  overlay.style.pointerEvents = "none"; // Don't block interactions
  overlay.style.zIndex = "9999"; // Place above all other elements

  document.body.appendChild(overlay);

  setTimeout(() => {
    overlay.remove();
  }, duration);
}

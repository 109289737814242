import markdown from "markdown-it";

export function Markdown({ children }: { children: string }) {
  if (!children) {
    return null;
  }
  const md = markdown();
  const html = md.render(children);
  return <div className="unreset" dangerouslySetInnerHTML={{ __html: html }} />;
}

import { useSignal } from "@preact/signals-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import { SignalType } from "@/lib/persistentsignal";

export function Button({
  className,
  children,
  onClick,
  disabled,
  disabledReason,
  title,
  secondary,
  ...props
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  disabledReason?: string;
  secondary?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const cls = twMerge(
    "rounded-md border bg-blue-400 hover:bg-blue-700 px-2",
    disabled ? "opacity-50 cursor-not-allowed" : "",
    secondary ? "bg-gray-300 text-gray-700 border-gray-400" : "",
    className
  );
  const error = useSignal<Error | null>(null);
  const running = useSignal(false);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (disabled) {
      return;
    }
    let resp: any;
    try {
      resp = onClick(event);
      error.value = null;
    } catch (e) {
      error.value = e as Error;
      console.error("Error in handler:", e);
    }
    if (resp && resp.then) {
      running.value = true;
      resp
        .catch((e: Error) => {
          error.value = e;
        })
        .finally(() => {
          running.value = false;
        });
    }
  }
  return (
    <button
      onClick={handleClick}
      className={cls}
      disabled={disabled}
      title={disabled ? disabledReason || title : title}
      {...props}
    >
      {children}
      {running.value && <span className="ml-2">...</span>}
      {error.value && (
        <div className="text-red-600 text-xs">{error.value.message}</div>
      )}
    </button>
  );
}

export function CheckButton({
  className,
  signal,
  on,
  off,
  onClass,
  offClass,
}: {
  className?: string;
  signal: SignalType<boolean>;
  on: React.ReactNode;
  off: React.ReactNode;
  onClass?: string;
  offClass?: string;
}) {
  onClass = onClass || "bg-green-600 border border-green-700 p-1";
  offClass = offClass || "bg-green-950 border border-green-700 p-1";
  const cls = twMerge(className, signal.value ? onClass : offClass);
  return (
    <Button
      className={cls}
      onClick={() => {
        signal.value = !signal.value;
      }}
    >
      {signal.value ? on : off}
    </Button>
  );
}

export function A({
  blank,
  className,
  ...props
}: { blank?: boolean } & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const p = { ...props };
  if (blank) {
    p.target = "_blank";
  }
  return (
    <a className={twMerge("text-cyan-600 hover:underline", className)} {...p} />
  );
}

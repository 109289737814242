export function isElementVisibleInScroll(
  element: HTMLElement,
  container?: HTMLElement
): boolean {
  if (!container) {
    let pos = element.parentElement;
    while (pos) {
      if (isOverflowElement(pos)) {
        container = pos;
        break;
      }
      pos = pos.parentElement;
    }
    if (!container) {
      throw new Error("No scrollable container found");
    }
  }
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const isWithinScrollTop =
    elementRect.top >= containerRect.top &&
    elementRect.top < containerRect.bottom;

  const isWithinScrollBottom =
    elementRect.bottom > containerRect.top &&
    elementRect.bottom <= containerRect.bottom;

  return isWithinScrollTop || isWithinScrollBottom;
}

export function isOverflowElement(element: HTMLElement): boolean {
  return element.scrollHeight > element.clientHeight;
}

export function getIdsInView() {
  const elements = Array.from(document.querySelectorAll("[data-id]"));
  const result: number[] = [];
  for (const element of elements) {
    if (isElementVisibleInScroll(element as HTMLElement)) {
      const idString = (element as HTMLElement).dataset.id;
      if (idString) {
        result.push(parseInt(idString, 10));
      }
    }
  }
  return result;
}

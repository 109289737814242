import { SignalType } from "./persistentsignal";

export class IndexedSignal<T> implements SignalType<T> {
  index: number;
  signal: SignalType<T[]>;

  constructor(signal: SignalType<T[]>, index: number) {
    this.index = index;
    this.signal = signal;
  }

  peek() {
    return this.signal.peek()[this.index];
  }

  get value() {
    return this.signal.value[this.index];
  }

  set value(value: T) {
    const values = this.signal.value;
    const newValues = [...values];
    newValues[this.index] = value;
    this.signal.value = newValues;
  }
}
